<div class="textcenter comabout">
  <img src="assets/img/logo.png" alt="Placement Master About Us">
</div>

<h2 class="textcenter"> About Us </h2>

<div class="aboutflex marbot">
  <div class="element">
    <div class="elementalign">
      <h3>About Us</h3>
      <img src="{{studentImage}}" alt="Placement Master Contact Us">
        <p>
          PlacementMaster helps students to prepare for their placements and land on their dream job in a top company. We aim at
          helping every student to get a position in a top company for their talents. We believe that every student has
          different talents and skillsets. Either you are an IT or non IT student, if you have the urge to learn and work in an
          IT-based industry then our platform is the best place for it. We help you accomplish the dream you've always dreamt
          of! Aspirants who prepared from our material are now in topmost companies in India like TCS, CTS, IBM, Accenture, and
          many more.
        </p>
        <p>
          Join PlacementMaster family and get instant updates on new materials and placements in companies
        </p>
        <p>
          Happy Learning!
        </p>
    </div>
  </div>

  <div class="element">
    <div class="elementalign">
      <h3>Our Approach</h3>
      <img src="assets/img/logo.png" alt="Placement Master Contact Us">
      <p>
        Placements......the heartbreaking part and also the most important phase for every college passing out students. It's indeed every student's aim whether they are topper or middler to get placed in a very prestigious organization with satisfying pay. So to achieve this we fall hard and equip us with as much knowledge as possible. Do you think just dumping things at last moment is going to help you out..... of course not ......so this knowledge had to be fed in slowly and steadily in an effective way during every phase so that it aids us throughout our lifetime.
      </p>
      <p>
        Resume ......As we all know the first impression should always be the best .....and nowadays 75% of judgment on our communication skills and level of creativity is assessed based on our resume .....here we have uploaded lots of resume templates for you to have them handy we have also experts to make u r resume a unique one for best prices
      </p>
    </div>
  </div>

  <div class="element">
    <div class="elementalign">
      <h3>Support</h3>
      <img src="{{supportImage}}" alt="Placement Master Contact Us">
      <p style="margin-top: 20px;">
        <i class="fa fa-envelope" aria-hidden="true"></i>
        <strong> support@placementmaster.in </strong> 
      </p>
    </div>
  </div>
</div>