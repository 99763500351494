import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { SidebarService } from '../sidebar/sidebar.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  supportImage = `https://firebasestorage.googleapis.com/v0/b/placement-material.appspot.com/o/support.png?alt=media&token=c2338fc1-88dd-47c6-8927-f01d0af151db`;

  studentImage = `https://i.pinimg.com/564x/46/5f/48/465f48c7f5a0d60b912377e1d6e5f848.jpg`;
  commonName: string;

    constructor(private meta: Meta,
    private head: Title, public sidebarservice: SidebarService) { 
      this.setSeo();
    }

  ngOnInit() {
  }

  setSeo() {
    this.head.setTitle('About Us - Placement Master');
    this.meta.updateTag({ name: 'description', content: "World’s largest placement preparation website - Placement Master"});
    this.meta.updateTag({ name: 'keywords', content: 'Placement Master About Us'});
    this.meta.updateTag({ name: 'share_url', content: window.location.href });
  }
}
