<nav id="sidebar" class="sidebar" [ngClass]="{'sidebar-bg' : hasBackgroundImage()}">
  <div class="sidebar-content">
    <perfect-scrollbar>
      <div class="sidebar-header">
        <a routerLink="/">Placement Master</a>
      </div>

      <div class="sidebar-profile">
        <div class="user-pic">
          <img class="img-responsive img-rounded" title="Placement Master" src="assets/img/logo.png" alt="Placement Master">
        </div>
        <div class="user-info">
          <span class="user-name martop10">
            <strong>Placement Master</strong> 
          </span>
        </div>
      </div>
      <div class="sidebar-menu">
        <ul>
          <li *ngFor="let menu of menus" [ngClass]="{'active': menu.active , 'sidebar-dropdown':menu.type === 'dropdown' , 'header-menu':menu.type === 'header'}">

            <span *ngIf="menu.type === 'header'">{{menu.title}}</span>

            <a *ngIf="menu.type !== 'header' && menu.type ==='simple'" (click)="passRouteName(menu.path)" [routerLink]="[menu.path]" (click)='toggle(menu)'>
              <i class="{{menu.icon}}"></i>
              <span>{{menu.title}}</span>
              <span *ngIf="menu.badge" class="badge badge-pill" [ngClass]="menu.badge.class">{{menu.badge.text}}</span>
            </a>

            <a *ngIf="menu.type !== 'header' && menu.type !=='simple'" (click)='toggle(menu)'>
              <i class="{{menu.icon}}"></i>
              <span>{{menu.title}}</span>
              <span *ngIf="menu.badge" class="badge badge-pill" [ngClass]="menu.badge.class">{{menu.badge.text}}</span>
            </a>

            <div *ngIf="menu.type === 'dropdown'" class="sidebar-submenu" [@slide]="getState(menu)">
              <ul>
                <li *ngFor="let submenu of menu.submenus">
                  <a [routerLink]="[submenu.path]" (click)="passRouteName(submenu.path)"> {{submenu.title}}
                    <span *ngIf="submenu.badge" class="badge badge-pill" [ngClass]="submenu.badge.class">{{submenu.badge.text}}</span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </perfect-scrollbar>
  </div>
</nav>
