import { Component, OnInit, DoCheck, OnDestroy } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { SidebarService } from '../sidebar/sidebar.service';

import questionData from '../_files/data.json';
import { ProductService } from '../product.service';
declare var paypal:any;

@Component({
  selector: 'app-questionpaper',
  templateUrl: './questionpaper.component.html',
  styleUrls: ['./questionpaper.component.css']
})

export class QuestionpaperComponent implements OnInit , DoCheck , OnDestroy {

  description = ``;
  keyword = ``;
  commonName;
  commonService;
  name = '';
  title = '';
  productLabel = "";
  companyList = [];
  currentData;
  loader = false;
  productList;
  domain = `https://app.placementmaster.in`;
  apiService: any;
  constructor(private meta: Meta,
    private head: Title, public sidebarservice: SidebarService ,
    public productService: ProductService) {
    this.setSeo();
    this.name = window.location.pathname.split('/')[2];
    this.checkCurrentData();
  }

  ngOnInit() {
  }
  capitalizeFirstLetter(string) {
    if (string) { 
      string = string.charAt(0).toUpperCase() + string.slice(1);
      string = this.captialName(string);
      return string;
    }
  }
  setSeo() {
    this.name = window.location.pathname.split('/')[2];
    this.commonName = window.location.pathname.split('/')[2] + " " + window.location.pathname.split('/')[1].replace('-', ' ');
    this.productList = questionData.find((e) => e.type === this.commonName);
    this.productLabel = this.capitalizeFirstLetter(window.location.pathname.split('/')[1].replace('-', ' '));
    this.title = this.productList && this.productList.title ? this.productList.title :
    this.capitalizeFirstLetter(window.location.pathname.split('/')[2]) + ` ${this.productLabel} | Placement Master`;
    this.description = this.productList && this.productList.meta_description ? this.productList.meta_description :
    `Collection of all the previous year placement papers conducted by ${this.capitalizeFirstLetter(this.name)}. It includes all questions from each section in the interview by ${this.capitalizeFirstLetter(this.name)}`;

    // SEO Technical Tag
    this.head.setTitle(this.title);
    this.meta.updateTag({ name: 'description', content: this.description });
    this.meta.updateTag({ name: 'og:description', content: this.description });
    this.meta.updateTag({ name: 'og:title', content: this.title });
    this.meta.updateTag({ name: 'og:image', content: this.productList && this.productList.image_link});
    this.meta.updateTag({ name: 'twitter:title', content: this.title });
    this.meta.updateTag({ name: 'share_url', content: 
    `${this.domain}${window.location.pathname}` });
    this.meta.updateTag({ name: 'og:url', content: `${this.domain}${window.location.pathname}`});
    this.meta.updateTag({ name: 'keywords', content: this.commonName });
  }
  ngDoCheck() {
   this.commonService = this.sidebarservice.pathName.subscribe(val => {
      if (val) this.setSeo();});
  }
  ngAfterViewInit() {
    this.commonService = this.sidebarservice.pathName.subscribe(val => {
      if (val) setTimeout(() => {
        this.checkCurrentData();
      }, 200);
    });
  }
  ngOnDestroy() {
    this.commonService.unsubscribe();
    if (this.apiService) this.apiService.unsubscribe();
  }

  checkCurrentData() {
    const paypalButton = document.querySelector('#paypal-button-container');
    if (paypalButton)  paypalButton.innerHTML = "";
    this.commonName = window.location.pathname.split('/')[2] + " " + window.location.pathname.split('/')[1].replace('-', ' ');
    this.productList = questionData.find((e) => e.type === this.commonName);
    this.renderPayPalButton('2.00' , this.productList);
  }

  captialName(name) {
    return name && name.length <= 5 ? name.toUpperCase() : name;
  }

  sendMail(payload) {
    try {
      this.productService.sendNotification(payload).subscribe((res) => {
        window.location.href = `${window.location.origin}/order-history`;
      }, (err) => {
        window.location.href = `${window.location.origin}/order-history`;
      });
    } catch (error) {
      window.location.href = `${window.location.origin}/order-history`;
    }
  }
  renderPayPalButton (USD , productData) {
    paypal.Buttons({
      style: {
        shape: 'rect',
        color: 'blue',
        layout: 'vertical',
        label: 'paypal',
      },
      createOrder: function (data, actions) {
        return actions.order.create({
          application_context: {
            brand_name: "Placement Master", 
            shipping_preference: 'NO_SHIPPING',// to hide address field
            payment_method: { payee_preferred: "UNRESTRICTED" }
          },
          purchase_units: [{
            description: "Placement Master", 
            soft_descriptor: "Placement Master",
            amount: {
              value: USD
            },
          }]
        });
      },
      onApprove:  (data, actions) => {
        return actions.order.capture().then((details) => {
          details.productList = {
            image_link: productData.image_link,
            payment_link: productData.payment_link,
            price: productData.price,
            file_size: productData.file_size
          };
          alert('Transaction completed by ' + details.payer.name.given_name);
          localStorage.setItem('productDetails' , JSON.stringify(details));
          const payload = {
            email: details && details.payer && details.payer.email_address,
            Name: details.payer.name.given_name,
            downloadLink: productData.payment_link,
            productImage: productData.image_link
          };
          this.sendMail(payload)
        });
      },
      onCancel: () => {},
      onError: () => {}
    }).render('#paypal-button-container');
  }
}
