<hr>
<h1 class="fontSize19 textcenter pad15">
  Order History
</h1>
<hr>

<div class="placementpaperDes placementPad maxcontent flex1 marR2">

  <div>
    <div class="orderBox">
      <div class="Welcome">
        Welcome
      </div>
      <div class="welcomeName">
        {{this.productDetails && this.productDetails.payer && this.productDetails.payer.name && this.productDetails.payer.name.given_name}}
      </div>
      <div class="welcometext">
        You have Successfully Purchased the Product
      </div>
      
      <div class="flex imageMargin">
        <div class="image">
          <img class="placementpaperimage fullwidth fullheight" alt="sat placement papers" title="sat placement papers"
            src="{{productDetails && productDetails.productList && productDetails.productList.image_link}}">
        </div>
      </div>
      <div class="imageMargin">
        <div class="combutton" (click)="downloadFile()"> 
          Download Files 
        </div>
      </div>

      <hr>
      <h1 class="fontSize19 textcenter pad15">
        Order Details
      </h1>
      <hr>
      <div class="flex imageMargin">
        <div class="textleft padpercentage width50">
          <div class="boldtext boxfield font15" *ngFor="let a of orderDetails;">
            {{ a.label}}
          </div>
        </div>
        <div class="textleft width50">
          <div class="boxfield font15" *ngFor="let a of orderDetails;">
            {{ a.value}}
          </div>
        </div>
      </div>

      <hr>
      <h1 class="fontSize19 textcenter pad15">
        Seller Details
      </h1>
      <hr>

      <div class="flex imageMargin">
        <div class="textleft padpercentage width50">
          <div class="boldtext boxfield font15" *ngFor="let a of sellerDetails;">
            {{ a.label}}
          </div>
        </div>
        <div class="textleft width50">
          <div class="boxfield font15" *ngFor="let a of sellerDetails;">
            {{ a.value}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>