import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  image = '../../assets/img/logo.png';
  title = `Placement Master India’s trusted most visited site for placement`;
  description = `Placement Master helps you for placement preparation and we provide placement papers, repeated aptitude questions,
  Interview questions`;
  keyword = 'placement paper , placement question paper , aptitude questions , placement preparation';
  companylist = [];
  domain = `https://app.placementmaster.in`;
  
  constructor(private meta: Meta,
    private head: Title) {
    this.head.setTitle(this.title);
    this.meta.updateTag({ name: 'description', content: this.description });
    this.meta.updateTag({ name: 'keywords', content: this.keyword });
    this.meta.updateTag({ name: 'og:title', content: this.title });
    this.meta.updateTag({ name: 'twitter:title', content: this.title });
    this.meta.updateTag({
      name: 'og:url', content:
        `${this.domain}${window.location.pathname}`
    });
    this.meta.updateTag({ name: 'keywords', content: this.keyword });
  }

  ngOnInit() {
    this.companylist = ['sat', 'act', 'gre', 'toefl', 'ielts', 'pte'];
  }

}
