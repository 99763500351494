<h1 class="fontSize19 textcenter">{{this.title}}</h1>

<div class="mainloader" *ngIf="loader">
  <div class="loaderplacement"></div>
</div>

<div class="flexrow mobilerow" *ngIf="productList">
  <div class="flex flex3">
    <div class="placementpaperDes placementPad width60">
      <div class="overview captial fw500"> Download {{captialName(this.name)}} Placement Papers for
        {{captialName(this.name)}} Test</div>
      <p [innerHTML]="productList.overview">
      </p>
    </div>
    <div class="placementpaperDes placementPad width60">
      <div class="overview fw500">
        Description
      </div>
      <p [innerHTML]="productList.description">
      </p>

    </div>
  </div>
  <div class="placementpaperDes placementPad maxcontent flex1 marR2">
    <div>
      <h3 class="font-rc product-title text-word-wrap-break-word textcenter captial">Download {{captialName(this.name)}}
        Placement Papers for
        {{captialName(this.name)}} exam
        preparation</h3>
      <div class="textcenterflex">
        <img class="placementpaperimage" alt="{{this.commonName}}" title="{{this.commonName}}"
          src="{{productList.image_link}}">
      </div>
      <div class="offer__info-block textcenterflex">
        <ul class="list-dotted soft--top flush--left">
          <li><i class="fa fa-file" aria-hidden="true"></i> Compressed File</li>
          <li>$ 2 USD</li>
          <li>{{productList.file_size}}</li>
          <li>ZIP File Format</li>
          <li>Secure Payment 💯</li>
        </ul>
      </div>
      <div class="textcenterflex">
        <div id="paypal-button-container"></div>
      </div>
    </div>

    <br>
    <br>
    <h6>Guide to make payment and download placement papers. </h6>
    <br>
    <ul>
      <li>Enter basic information like name, mailId, mobile number to proceed for the payment screen.</li> <br>

      <li>Check the total amount to be paid and select a payment method from available options to complete the online
        payment process.</li> <br>

      <li>Once the online payment is successful, the placement material’s download option is made available and the
        preparation is yet to be started.</li>
    </ul>
    <div class="textcenter">
      <iframe src="https://www.youtube.com/embed/p_rtvBChfdE" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </div>
  </div>
</div>


<br>
<br>


<div class="flexrow">
  <div>
    <img class="fullwidth fullheight" src="../../assets/img/support.png">
  </div>
  <div class="textcenter comboxPadding element normalWidth customerBox">

      <div> Support Email ID : <strong>support@placementmaster.in</strong></div> <br>

      <div><strong>24/7 SUPPORT</strong></div> <br> 

      <div>World Wide Customer Service</div> <br>

      <div>
        <img class="fullwidth" src="../../assets/img/worlwide.png">
      </div>
      <br><br>
      <div>
        <strong>"Make the customer’s problem your problem."</strong> 
      </div>
  </div>
</div>

<div class="placementpaperDes placementPad width60">
  <div class="overview fw500">
    Reviews and Ratings
  </div>
  <i class="fa fa-star rating" aria-hidden="true"></i>
  <i class="fa fa-star rating" aria-hidden="true"></i>
  <i class="fa fa-star rating" aria-hidden="true"></i>
  <i class="fa fa-star rating" aria-hidden="true"></i>
  <i class="fa fa-star" aria-hidden="true"></i>
  <span> {{productList && productList.ratings}} out of 5 </span> ( {{productList && productList.rating_count}} )
</div>

<div class="hashtagcontainer">
  <a routerLink="/preparation/{{this.name}}" class="hashtag">{{captialName(this.name)}} </a>
  <a routerLink="/practice-questions/{{this.name}}" class="hashtag">{{captialName(this.name)}}  Practice Questions</a>
</div>