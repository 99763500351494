import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  toggled = false;
  _hasBackgroundImage = true;
  menus = [
    {
      title: 'general',
      type: 'header'
    },
    {
      title: 'Dashboard',
      icon: 'fa fa-tachometer-alt',
      active: false,
      type: 'simple',
      badge: {
        text: 'New ',
        class: 'badge-warning'
      },
      path : '/' 
    },
    {
      title: 'Question Paper',
      icon: 'fa fa-file',
      active: false,
      type: 'dropdown',
      badge: {
        text: '5',
        class: 'badge-danger'
      },
      submenus: [
        {
          title: 'SAT',
          path : '/practice-questions/sat'
        },
        {
          title: 'ACT',
          path : '/practice-questions/act'
        },
        {
          title: 'GRE',
          path : '/practice-questions/gre'
        },
        {
          title: 'TOEFL',
          path : '/practice-questions/toefl'
        },
        {
          title: 'IELTS',
          path : '/practice-questions/ielts'
        },
        {
          title: 'PTE',
          path : '/practice-questions/pte'
        },
      ]
    },
    {
      title: 'Sample Questions',
      icon: 'fa fa-snowflake',
      active: false,
      type: 'dropdown',
      submenus: [
        {
          title: 'SAT',
          path : '/sample-questions/sat'
        },
        {
          title: 'ACT',
          path : '/sample-questions/act'
        },
        {
          title: 'GRE',
          path : '/sample-questions/gre'
        },
        {
          title: 'TOEFL',
          path : '/sample-questions/toefl'
        },
        {
          title: 'IELTS',
          path : '/sample-questions/ielts'
        },
        {
          title: 'PTE',
          path : '/sample-questions/pte'
        },
      ]
    },
    {
      title: 'Preparation',
      icon: 'fa fa-user',
      active: false,
      type: 'dropdown',
      submenus: [
        {
          title: 'SAT',
          path : '/preparation/sat'
        },
        {
          title: 'ACT',
          path : '/preparation/act'
        },
        {
          title: 'GRE',
          path : '/preparation/gre'
        },
        {
          title: 'TOEFL',
          path : '/preparation/toefl'
        },
        {
          title: 'IELTS',
          path : '/preparation/ielts'
        } ,
        {
          title: 'PTE',
          path : '/preparation/pte'
        },
      ]
    },
    // {
    //   title: 'Download Resume',
    //   icon: 'fa fa-download',
    //   active: false,
    //   path : '/resume-template-format/download',
    //   type: 'simple',
    // },
    {
      title: 'Support',
      type: 'header'
    },
    {
      title: 'Documentation',
      icon: 'fa fa-book',
      active: false,
      type: 'simple',
      path : '/',
      badge: {
        text: 'Beta',
        class: 'badge-primary'
      },
    },
    {
      title: 'About Us',
      icon: 'fa fa-comment',
      path : '/about-us',
      active: false,
      type: 'simple'
    },
    {
      title: 'Contact',
      icon: 'fa fa-envelope',
      path : '/contact',
      active: false,
      type: 'simple'
    }
  ];
  pathName;
  constructor() { 
    this.pathName = new BehaviorSubject(''); 
    if (document.documentElement.clientWidth <= 1024)  {
      this.toggled = true;
    }
  }

  toggle() {
    this.toggled = ! this.toggled;
  }

  getSidebarState() {
    return this.toggled;
  }

  setSidebarState(state: boolean) {
    this.toggled = state;
  }

  getMenuList() {
    return this.menus;
  }

  get hasBackgroundImage() {
    return this._hasBackgroundImage;
  }

  set hasBackgroundImage(hasBackgroundImage) {
    this._hasBackgroundImage = hasBackgroundImage;
  }
}
