
<div class="textcenter comabout">
  <img class="imageHeight" src={{image}} alt="Placement Master About Us">
</div>

<hr>
<h1 class="fontSize19 textcenter pad15">
  Placement Master world’s trusted and People's most visited website for placement
</h1>
<hr>


<div class="flexrow">
  <div class="pad22 textcenter">
    <p>
      <img src="https://dynamic.brandcrowd.com/asset/logo/4a0ae3f8-b51d-457d-b109-2f52711b4fd6/logo?v=4&text=eBooks" alt="Placement Master About Us">
    </p>
    <h5> eBooks </h5>
    <div>
      <p>
        Get access to all the repeated aptitude questions with solutions. 
      </p>
    </div>
  </div>
  <div class="pad22 textcenter">
    <p>
      <img class="imgq" src="https://dynamic.brandcrowd.com/asset/logo/2a056b05-ac9b-404f-a619-eba59f92ef48/logo?v=4&text=Software" alt="Placement Master About Us">
    </p>
    <h5> Software (Saas) </h5>
    <div>
      <p>
        Code programs with different logics and get your solutions from us
      </p>
    </div>
  </div>
  <div class="pad22 textcenter">
    <p>
      <img class="imgq" src="https://dynamic.brandcrowd.com/asset/logo/b0e1aaef-08b4-425b-bfce-3dc37a3eee11/logo?v=4&text=Photography" alt="Placement Master About Us">
    </p>
    <h5> Photography </h5>
    <div>
      <p>
        Our placement papers are formed using the exam pattern and previous years questions of the respective companies.
      </p>
    </div>
  </div>
</div>

<div class="flexrow">
  <div>
    <img class="fullwidth fullheight" src="../../assets/img/support.png">
  </div>
  <div class="textcenter comboxPadding element normalWidth customerBox">

      <div> Support Email ID : <strong>support@placementmaster.in</strong></div> <br>

      <div><strong>24/7 SUPPORT</strong></div> <br> 

      <div>World Wide Customer Service</div> <br>

      <div>
        <img class="fullwidth" src="../../assets/img/worlwide.png">
      </div>
      <br><br>
      <div>
        <strong>"Just trust yourself, then you will know how to live."</strong> 
      </div>
  </div>
</div>

<hr>
<h1 class="fontSize19 pad15 out-text">
   Companies - Placement Master
</h1>
<hr>

<div class="flexwrap">
    <div class="companies-boxes" *ngFor="let c of companylist;">
        <div class="companies-in-box out-text">
          <a routerLink="/practice-questions/{{c}}">{{c}}</a>
        </div>
    </div>
</div>



<div class="flexrow">
  <div class="hightlights-box">
    <h5 class="color1">Placement Paper</h5>
    Placement papers are provided to practice the questions from previous years
  </div>

  <div class="hightlights-box">
    <h5 class="color1">Interview Questions</h5>
    Interview Questions will help you with the way to approach HR and grab the job in your hands.
  </div>

  <div class="hightlights-box">
    <h5 class="color1">Aptitude Questions</h5>
    Practice our Aptitude Questions which determines your propensity and skills assessing prior knowledge.
  </div>
</div>


