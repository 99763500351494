import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QuestionpaperComponent } from './question-paper/questionpaper.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactComponent } from './contact/contact.component';
import { OrderHistoryComponent } from './order-history/order-history.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    component: DashboardComponent,
    pathMatch: 'full',
  },
  {
    path: 'practice-questions/:name',
    component: QuestionpaperComponent
  },
  {
    path: 'sample-questions/:name',
    component: QuestionpaperComponent
  },
  {
    path: 'preparation/:name',
    component: QuestionpaperComponent
  },
  {
    path: 'about-us',
    component: AboutUsComponent
  },
  {
    path: 'order-history',
    component: OrderHistoryComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
