<div class="textcenter comabout">
  <img class="imageHeight" src="assets/img/logo.png" alt="Placement Master Contact Us">
</div>

<h3 class="textcenter"> Contact Us <i class="fa fa-envelope" aria-hidden="true"></i> </h3>


<div class="textcenter marbot">
  <div class="aboutflex singlecontact">
    <div class="element">
      <div class="elementalign">
        <h3>Email </h3>
        <br>
        <br>
        <ul class="list-dotted soft--top flush--left textleft">
          <li>Emails are crummy and Ancient. But, we still provide an email support system.
          </li> <br>
          <li>
            Email us on the following – <strong>support@placementmaster.in</strong>
          </li>
        </ul>
        <br>
        <h3>Support</h3>
        <br>
        <p>
          <i class="fa fa-envelope" aria-hidden="true"></i>
          <strong> support@placementmaster.in </strong>
        </p>
      </div>
    </div>
  </div>
  <br>
  <div class="aboutflex singlecontact">
    <div class="element">
      <div class="elementalign">
        <h3>Twitter</h3>
        <br>
        <br>
        <ul class="list-dotted soft--top flush--left textleft">
          <li>Any urgent queries to contact us !!! we provide a twitter support system also. 
          </li> <br>
          <li>
            <i class="fa fa-comments" aria-hidden="true"></i>
            Contact us on the following – <a href="https://twitter.com/PlacementM?s=08">Placement Master Twitter</a>
          </li>
        </ul>
        <br>
        <h3>Support</h3>
        <br>
        <p>
          Take a look at Placement master (@PlacementM): <a href="https://twitter.com/PlacementM?s=08"> Placement
            Master</a>
        </p>
      </div>
    </div>
  </div>

  <br>
  <div class="aboutflex singlecontact">
    <div class="element">
      <div class="elementalign">
        <h3>Facebook</h3>
        <br>
        <br>
        <ul class="list-dotted soft--top flush--left textleft">
          <li>We resolve your queries on Facebook too, Placement Master is the most active on   Facebook, so if you have got any urgent queries to contact us !!!.
          </li> <br>
          <li>
            <i class="fa fa-comments" aria-hidden="true"></i>
            Contact us on the following – <a href="https://www.facebook.com/placementmaster/">Placement Master Facebook</a>
          </li> <br>
          <li> <a href="https://onlinedevtools.in/">Developer</a> Team</li>
        </ul>
        <br>
        <h3>Support</h3>
        <br>
        <p>
          To message us just follow this link – <a href="https://www.facebook.com/placementmaster/">
            Placement Master Facebook</a>
        </p>
      </div>
    </div>
  </div>
</div>