import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.css']
})
export class OrderHistoryComponent implements OnInit {

  productDetails;
  orderDetails = [];
  sellerDetails = [];
  constructor(private meta: Meta, private head: Title) {
    const details = localStorage.getItem('productDetails');
    this.productDetails = details && JSON.parse(details);
    this.orderDetails = [
      { label: 'First Name', value: this.productDetails && this.productDetails.payer && this.productDetails.payer.name && this.productDetails.payer.name.given_name },
      { label: 'Last Name', value: this.productDetails && this.productDetails.payer && this.productDetails.payer.name && this.productDetails.payer.name.surname },
      { label: 'Email ID', value: this.productDetails && this.productDetails.payer && this.productDetails.payer.email_address },
      { label: 'Status', value: this.productDetails && this.productDetails.status },
      { label: 'Created At', value: this.productDetails && this.productDetails.create_time && new Date(this.productDetails.create_time).toLocaleString() },
    ];
    
    this.sellerDetails = [
      { label: 'Paid To', value: 'Placement Master' },
      { label: 'Seller Email', value: 'support@placementmaster.in' },
    ];
   }

  downloadFile() {
    if ( this.productDetails && this.productDetails.productList && this.productDetails.productList.payment_link) {
      window.open(this.productDetails.productList.payment_link);
    }
  } 
  ngOnInit(): void {
    this.head.setTitle(`Order History | Placement Master`);
    this.meta.updateTag({ name: 'description', content: `Order History | Placement Master`});
  }

}
